.message {
	@include clearfix;
	@include material-shadow();

	background: $color-blue-lighter;
	font-weight: $weight-normal;
	margin-bottom: 20px;
	padding: 10px 12px;

	p {
		margin: 0;
		padding-left: 28px;
	}

	.fa {
		float: left;
		margin-right: 8px;
		font-size: 20px;
		color: $color-blue;
		display: inline-block;
		vertical-align: middle;
	}
}
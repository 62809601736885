table.contenttable, table.ce-table {
    border-collapse: collapse;
    font-size: 14px;
    margin-bottom: 20px;

    th, td {
        position: relative;
        padding: 15px;
        border-bottom: 1px solid $color-blue;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            height: 10px;
            width: 1px;
            background-color: $color-blue;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    th {
        text-align: left;
        font-weight: $weight-medium;
    }

    tr:nth-child(odd) {
        td {
            background-color: $color-blue-lighter;
        }
    }

    @include media($screen-xs) {
        display: none;
    }
}

table.ce-mobile-table {

    border-collapse: collapse;

    display: none;

    width: auto;

    max-width: 100%;

    margin-bottom: 20px;

    th {
        font-weight: bold;
    }

    tr.odd {
        background-color: $color-blue-lighter;
    }

    td, th {
        text-align: left;
        padding: 10px 20px;
    }

    @include media($screen-xs) {
        display: block;
    }

}
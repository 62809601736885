.ce-menu {
	@include list-style-none;
	margin-bottom: 20px;
	li {
		a {
			display: block;
			transform: scale(1);
			color: $color-text-dark;
			font-size: 22px;
			font-weight: $weight-normal;

			padding: 9px 25px 9px (45px+25px);

			@include icon($fa-var-caret-right) {
				font-size: 1em;
				position: relative;
				left: -35px;
			}
		}
	}

	.sidebar-column & {
		li {

			margin: 0 -25px;
			a {
				$animation-duration: 225ms;
				@include material-shadow(0);
				@include transition(all $animation-duration easing());

				&:before {
					@include transition(all $animation-duration easing());
					color: $color-white;
				}
				max-width: 100%;

				&:hover {

					transform: scale(1.05);
					@include material-shadow(2);
					@include transition(all $animation-duration easing());

					background-color: $color-blue-lighter;

					&:before {
						@include transition(all $animation-duration easing());
						left: -10px;
						color: $color-text-dark;
					}
				}
			}
		}
	}
}
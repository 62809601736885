.tabs {
    .select-wrapper {
        display: none;
        width: 100%;

        padding-top: 20px;

        .select {

        }

        @include media($screen-md) {
            display: block;
        }

        @include media($screen-sm) {
            display: none;
        }

        @include media($screen-xs) {
            display: block;
        }

    }
    nav.tabs-navigation {
        display: block;

        @include media($screen-md) {
            display: none;
        }

        @include media($screen-sm) {
            display: block;
        }

        @include media($screen-xs) {
            display: none;
        }

        ul {
            @include list-style-none;
            padding: 0;
            margin: 0 -30px 20px;

            @include align-items(stretch);
            @include display(flex);
            @include flex-direction(row);
            @include justify-content(flex-start);

            li {
                @include flex(1);
                padding: 10px 14px;

                background: $color-blue-lighter;

                font-size: 14px;
                font-weight: $weight-bold;

                a {
                    color: $color-text;
                }


                border-left: 1px solid $color-blue-light;

                &:first-child {
                    border-left: none;
                }

                &.active {
                    background: $color-blue-light;
                }

                &:hover {
                    cursor: pointer;
                    @include material-shadow(1);
                }
            }
        }
    }

    .tabs-content {
        position: relative;
        article {
            position: absolute;
            z-index: 5;
            display: none;
            top: 0;
            left: 0;
            right: 0;

            &.active {
                display: block;
            }
        }
    }
}

.f3-widget-paginator {
	@include list-style-none;
	@include material-shadow();
	position: relative;
	text-align: center;
	margin-bottom: 20px;
	background-color: $color-white;
	padding: 10px 0;

	ul {
		margin: 0;
	}

	li {
		display: inline-block;
		margin: 0;
		padding: 0;
		a,
		&.current {
			min-width: 20px;
			text-align: center;
			font-weight: $weight-bold;
		}
		a {
			display: block;
		}
		&.previous,
		&.next {
			position: absolute;
			top: 7px;
			a {
				color: $color-text;
				font-weight: $weight-bold;
				text-decoration: none;
				&:hover {
					@include transition(all 275ms easing());
					color: mix($color-white, $color-text, 25%);
				}
			}
		}
		&.previous {
			left: 20px;
			a {
				@include transition(all 275ms easing());
				@include icon($fa-var-caret-left) {
					position: relative;
					top: 2px;
					left: 0;
					font-size: 1.4em;
					margin-right: 5px;
					@include transition(left 275ms easing());
				}

				&:hover {
					&:before {
						left: 2px;
					}
				}
			}
		}
		&.next {
			right: 20px;
			a {
				@include transition(all 275ms easing());
				@include icon($fa-var-caret-right, after) {
					position: relative;
					top: 2px;
					right: 0;
					font-size: 1.4em;
					margin-left: 5px;
					@include transition(right 275ms easing());
				}

				&:hover {
					&:after {
						right: 2px;
					}
				}
			}
		}
		.max-pages {
			display: none;
		}
		@include media($screen-xs) {
			&.pages {
				display: none;
			}
			.max-pages {
				display: inline;
			}
		}
	}
}
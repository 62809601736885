@import "../../../Vendor/bourbon/app/assets/stylesheets/bourbon";
@import "../../../Vendor/neat/app/assets/stylesheets/neat-helpers";

$visual-grid: false;
$visual-grid-color: #f00;
$visual-grid-index: front;
$visual-grid-opacity: 0.03;

$column: 68px;
$gutter: 30px;
$grid-columns: 12;
$max-width: 1148px;

$screen-lg: new-breakpoint(max-width 1200px 12);
$screen-md: new-breakpoint(max-width 1024px 12);
$screen-sm: new-breakpoint(max-width  840px 12);
$screen-xs: new-breakpoint(max-width  480px  4);
/*
$screen-lg-min: new-breakpoint(min-width 1200px 12);
$screen-md-min: new-breakpoint(min-width 1024px 12);
$screen-sm-min: new-breakpoint(min-width  768px 12);
$screen-xs-min: new-breakpoint(min-width  480px  4);
*/
@import "functions";
@import "../../../Vendor/bourbon/app/assets/stylesheets/bourbon";
@import "../../../Vendor/font-awesome-sass/assets/stylesheets/font-awesome";

@mixin visually-hidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin hover($transition: all 375ms easing(swift)) {
	@include transition($transition);
	&:hover {
		@include transition($transition);
	}
}

@mixin bleed($width: $gutter) {
	@include margin(null (-$width));
	@include padding(null $width);
}

@mixin list-style-none() {
	margin: 0;
	padding: 0;
	li {
		&:before {
			width: auto;
			height: auto;
			position: static;
			top: 0;
			left: 0;
			border-radius: 0;
			background-color: transparent;
		}
	}
}

// Icon Mixin
@mixin icon($character, $position: before) {
	@if $position == null {
		@include fa-icon();
		content: '#{$character}';
		@content;
	} @else {
		&:#{$position} {
			@include fa-icon();
			content: '#{$character}';
			@content;
		}
	}
}

@keyframes popup-level1 {
	0% {
		top: 3px;
		box-shadow: 0 0px 0px rgba(0,0,0,0.11), 0 0px 0px rgba(0,0,0,0.24);
	}
	100% {
		top: 0px;
		box-shadow: 0 1px 3px rgba(0,0,0,0.11), 0 1px 1px rgba(0,0,0,0.24);
	}
}

@keyframes popup-level2 {
	0% {
		top: 8px;
		box-shadow: 0 1px 3px rgba(0,0,0,0.11), 0 1px 1px rgba(0,0,0,0.24);
	}
	100% {
		top: 0px;
		box-shadow: 0 4px 8px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.16);
	}
}

@mixin material-shadow($level: 1, $animation: no) {

	@if $animation == yes {

		position: relative;
		@if $level == 1 {
			top: 3px;
			box-shadow: 0 1px 1px rgba(0,0,0,0.0), 0 1px 1px rgba(0,0,0,0.10);
		}

		@if $level == 2 {
			top: 8px;
			box-shadow: 0 1px 3px rgba(0,0,0,0.11), 0 1px 1px rgba(0,0,0,0.24);
		}

		@include animation(popup-level#{$level} 745ms easing(swift-in));
		@include animation-delay(1s);
		@include animation-fill-mode(forwards);

	} @else {
		@if $level == 0 {
			box-shadow: 0 0px 0px rgba(0,0,0,0), 0 0px 0px rgba(0,0,0,0);
		}
		@if $level == 1 {
			box-shadow: 0 1px 3px rgba(0,0,0,0.11), 0 1px 1px rgba(0,0,0,0.24);
		}
		@if $level == 2 {
			box-shadow: 0 4px 8px rgba(0,0,0,0.25), 0 3px 3px rgba(0,0,0,0.16);
		}
		@if $level == 3 {
			box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
		}
	}
}

@mixin portfolio-list() {
	ul {
		@include list-style-none;
		@include clearfix;

		margin-bottom: 20px;

		li {
			display: block;
			float: left;
			width: auto;

			margin-right: 10px;
			margin-bottom: 10px;

			background: $color-blue;

			color: $color-white;
			font-size: 14px;
			font-weight: $weight-bold;
			padding: 2px 10px 0 10px;
			height: 25px;
			-webkit-border-radius: 12px;
			-moz-border-radius: 12px;
			border-radius: 12px;

			i {
				font-size: 12px;
				font-weight: $weight-normal;
			}
		}
	}
}
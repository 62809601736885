.accordion.initialized {

    margin: 2em 0;

    .accordion-element {

        overflow: hidden;

        .accordion-header {

            font-size: 22px;
            color: $color-white;

            display: block;
            padding: 7px 40px 6px 50px;
            margin: 0;

            position: relative;

            background: $color-blue;
            @include material-shadow();

            cursor: pointer;

            @include icon($fa-var-caret-right) {
                position: absolute;
                font-size: 24px;
                top: 9px;
                left: 30px;
                //margin-right: 8px;
                width: 9px;
                height: 24px;
                @include transition(transform 200ms easing());
                @include transform-origin(50% 50%);
                @include transform(rotate(0deg));
            }

        }

        .accordion-content {

            display: block;
            overflow: hidden;
            height: 0;

            .accordion-content-wrapper {
                padding: 10px 30px;
            }

        }


        &.open {
            .accordion-header:before {
                @include transform(rotate(90deg));
            }
        }

    }

}


.academy {
    .item {
        @include outer-container();
        @include material-shadow();
        background-color: $color-white;
        margin-bottom: 20px;
        .image {
            @include span-columns(3 of 8);
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .description {
            @include span-columns(5 of 8);
        }
        .description.no-image {
            @include span-columns(8 of 8);
            padding: 0 30px;
        }
    }
}

.academy-single {
    @include outer-container();
    @include material-shadow();
    background-color: $color-white;
    padding: 30px;

    .image {
        float: right;
        max-width: 30%;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    .disclaimer {
        margin: 20px 0;
        padding: 1px 20px;
        background: $color-blue-lighter;
    }

    .links li:before {
        background: none;
    }
}
.academy-info, .academy-search {
    @include material-shadow();
    position: relative;
    margin-bottom: 20px;
    background-color: $color-white;
    padding: 10px 30px;
}


.academy-search {
    form {
        display: flex;
        flex-wrap: wrap;
    }
    .powermail_frontend_search_container {
        .select-wrapper {
            width: calc(100% - 20px);
        }
        margin-bottom: 20px;
        width: 50%;

        @include media($screen-sm) {
            width: 100%;
        }
    }
}
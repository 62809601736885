#startpage {
	#content {
		.ce >,
		.news-list-view > {
			h1,h2,h3,h4,h5 {
				@include margin(null 30px);
				margin-left: 30px;
				font-weight: $weight-light;
				color: $color-white;
				font-size: 36px;
				line-height: 40px;
			}
		}

	}

	.sidebar-column {
		margin-top: 20px;
	}
}
$base-font-size: 16px;
$base-line-height: 20px;
$paragraph-spacing: 16px;

$em-base: $base-font-size;

$weight-light: 200;
$weight-normal: 400;
$weight-medium: 500;
$weight-bold: 700;
$weight-extrabold: 800;
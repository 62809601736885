.tx-kesearch-pi1 {

    margin-bottom: 20px;

    .search-form {
        position: relative;
        input {
            width: 100%;
        }
        button {
            position: absolute;
            top: 0;
            right: 0;
            margin: 0;
        }
        .kesearchbox {
            padding-right: 99px;
        }
    }

    .result-list-item {
        @include clearfix;
        @extend .ce-white-tile;
        padding: 0 30px;
    }

    .hit {
        font-weight: $weight-medium;
        color: $color-blue-dark;
    }
}

#top-search {
    position: absolute;
    z-index: 60;
    top: 174px;
    right: 0;

    width: column-width(3, true);

    span {
        @include visually-hidden;
    }

    .text-field-wrapper {
        padding-right: 36px;
    }

    input {
        width: 100%;

        background-color: $color-white;
        @include material-shadow();
    }

    button {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        margin: 0;
        .fa {
            position: relative;
            left: -2px
        }
    }

    @include media($screen-lg) {
        right: 30px;
        width: column-width(2, true);
    }

    @include media($screen-md) {
        right: 30px;
        width: column-width(2, true);
    }

    @include media(max-width 1009px) {
        display: none;

        &.show {
            display: block;
        }
        button {
            right: 10px;
        }

        left: 10px;
        right: 10px;
        top: 70px;
    }

    @include media(max-width 1009px) {
        display: none;

        &.show {
            display: block;
        }
        button {
            right: 10px;
        }
        width: auto;
        top: 230px;
        left: 30px;
        right: 20px;
    }

    @include media(max-width 840px) {
        top: 160px;
        left: 10px;
        right: 0px;
    }

    @include media($screen-xs) {
        top: 70px;
    }
}
// Basic colors
$color-white: #fff !default;
$color-black: #000 !default;

// EASC colors
$color-yellow: #fee603;
$color-blue-darker: #0c0e5e;
$color-blue-dark: #111384;
$color-blue-medium: #3765a3;
$color-blue: #4d8de4;
$color-blue-light: #d0e1f8;
$color-blue-lighter: #f1f6fd;
$color-lilac: #a4a6e1;

// Gradients
$gradient-button-dark: linear-gradient($color-blue-dark, $color-blue-darker);
$gradient-button-dark-hover: linear-gradient(mix(#fff,$color-blue-dark, 50%), $color-blue-darker);
$gradient-button-light: linear-gradient($color-blue, $color-blue-medium);
$gradient-button-light-hover: linear-gradient(lighten($color-blue, 18%),$color-blue-medium);

// Text
$color-text-grey: #a0a0a0;
$color-text-grey-light: #dadada;
$color-text: #4c4c62;
$color-text-dark: #22222c;

// Background
$color-bg-grey-light: #f1f6fc;

// Table
$color-row-odd: #becee3;

// Error
$color-error: #d88080;
$_previous-block: null !global;
$_current-block: null !global;
$_previous-element: null !global;
$_current-element: null !global;
$_current-modifier: null !global;

@mixin b($block) {
	$_previous-block: $_current-block;
	$_current-block: $block !global;
	$_previous-element: $_current-element;
	$_current-element: null !global;
	.#{$block} {
		@content;
	}
	$_current-block: $_previous-block !global;
	$_current-element: $_previous-element !global;
}

@mixin e($element) {
	@if type-of($element) == 'list' {
		@each $e in $element {
			$_previous-element: $_current-element;
			$_current-element: $e !global;
			.#{$_current-block}__#{$e} {
				@content;
			}
			$_current-element: null !global;
			$_current-element: $_previous-element !global;
		}
	} @else {
		$_previous-element: $_current-element;
		$_current-element: $element !global;
		.#{$_current-block}__#{$element} {
			@content;
		}
		$_current-element: $_previous-element !global;
	}
}

@mixin m($modifier) {
	@if $_current-element != null {
		@if type-of($modifier) == 'list' {
			@each $m in $modifier {
				$_current-modifier: $modifier !global;
				&.#{$_current-block}__#{$_current-element}--#{$m} {
					@content;
				}
				$_current-modifier: null !global;
			}
		} @else {
			$_current-modifier: $modifier !global;
			&.#{$_current-block}__#{$_current-element}--#{$modifier} {
				@content;
			}
			$_current-modifier: null !global;
		}
	} @else {
		@if type-of($modifier) == 'list' {
			@each $m in $modifier {
				$_current-modifier: $modifier !global;
				&.#{$_current-block}--#{$m} {
					@content;
				}
				$_current-modifier: null !global;
			}
		} @else {
			$_current-modifier: $modifier !global;
			&.#{$_current-block}--#{$modifier} {
				@content;
			}
			$_current-modifier: null !global;
		}
	}
}

@function b() {
	@return $_current-block;
}

@function e($e: $_current-element) {
	@return #{$_current-block}__#{$e};
}

@function m($m: $_current-modifier, $e: $_current-element) {
	@if $_current-element == null {
		@return #{$_current-block}--#{$m};
	}@else {
		@return #{$_current-block}__#{$e}--#{$m};
	}
}

.info-box {
	background: $color-blue-lighter;
	padding: 10px;

	font-weight: $weight-normal;

	@include media($screen-lg) {
		float: none;
		width: 100%;
		margin-bottom: 20px;
	}

	@include material-shadow();

	p {
		padding-right: 50px;
		margin: 0;
	}

	.fa {
		float: right;
		font-size: 50px;
		color: $color-blue;
	}
}
@mixin flag() {
	width: 32px;
	height: 24px;
	margin: 0 5px 0 0;
	display: block;
	position: relative;
	float: left;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;

	@include m('de') { background-image: url(../Images/lang_de_2x.png); }
	@include m('en') { background-image: url(../Images/lang_en_2x.png); }
	@include m('es') { background-image: url(../Images/lang_es_2x.png); }
}


@include b('language-navigation') {

	&:hover {
		@include e('list') {
			display: block !important;
		}
	}

	@include e('current-language') {
		padding: 5px 0 5px 5px;
		min-width: 200px;
		background-color: $color-white;
		@include clearfix;

		@include media($screen-xs) {
			min-width: 160px;
		}

		@include e('flag') {
			@include flag();
		}
		@include e('caption') {
			font-size: 18px;
			line-height: 24px;
			display: block;
			float: left;
			margin: 0 5px 0 0;
		}
		i {
			width: 34px;
			height: 34px;
			background: rgb(16,20,132);
			background: -moz-linear-gradient(180deg, rgba(16,20,132,1) 0%, rgba(11,14,94,1) 100%);
			background: -webkit-linear-gradient(180deg, rgba(16,20,132,1) 0%, rgba(11,14,94,1) 100%);
			background: linear-gradient(180deg, rgba(16,20,132,1) 0%, rgba(11,14,94,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#101484",endColorstr="#0b0e5e",GradientType=1);
			color: $color-white;
			position: absolute;
			top: 0;
			right: 0;
			font-size: 17px;

			&:before {
				position: absolute;
				top: calc(50% - 2px);
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	@include e('list') {
		margin: 0 !important;
		max-width: 200px;
		background-color: $color-white;
		display: none !important;

		@include media($screen-xs) {
			max-width: 160px;
		}

		@include e('item') {
			display: block;
			margin: 0 !important;
			width: 100%;
			padding: 5px;

			@include m('active') {
				background-color: $color-bg-grey-light;
			}

			&:hover {
				background-color: $color-blue-light;
			}

			@include e('link') {
				display: block;
				@include clearfix;

				@include e('item-flag') {
					@include flag();
				}
				@include e('item-caption') {
					font-size: 18px;
					line-height: 24px;
					float: left;
				}
			}
		}
	}
}
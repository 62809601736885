@import "../../../Vendor/neat/app/assets/stylesheets/neat-helpers";

.content-wrapper {
	@include outer-container();
}

.content-column {
	@include span-columns(8);

	@include media($screen-sm) {
		margin-bottom: 20px;
	}

	&.transparent {
		> .ce {
			> h1,
			> h2 {
				font-size: 26px;
				line-height: 32px;
				//font-weight: $weight-light;
				@include margin(null 30px);
				a {
					color: $color-white;
				}
			}
		}
		> .content-container, > .two-column-container {
			background: $color-white;
			padding: 0 30px 20px;
			@include material-shadow();
			margin-bottom: 20px;

		}
	}

	.content-container.button-container {
		margin-left: -1.5%;
		margin-right: -1.5%;
	}

	&.boxed {
		background: $color-white;
		padding: 0 30px 20px;
		@include material-shadow();
	}
}

.sidebar-column {
	@include span-columns(4);

	h1,h2,h3,h4,h5 {
		color: $color-text-dark;
		font-weight: $weight-light;
		font-size: 26px;
		line-height: 28px;
	}

	.ce {
		overflow: hidden;
		@include material-shadow();
		background-color: $color-blue-light;
		padding: 0 25px;
		margin-bottom: 20px;
	}
}

@include media($screen-sm) {
	.content-column {
		@include span-columns(12);
	}

	.sidebar-column {
		@include span-columns(12);
		.ce {
			@include span-columns(6);
			&:nth-child(even) {
				@include omega();
			}
		}
	}
}

@include media($screen-xs) {
	.content-column {
		@include span-columns(4);
	}

	.sidebar-column {
		@include span-columns(4);
		.ce {
			@include span-columns(4);
		}
	}
}
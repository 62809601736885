.social-media {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin: 0 5px 30px;
        list-style: none outside none;

        &:before {
            display: none;
        }

        a {
            img {
                width: 40px;
                height: 40px;
            }
        }
    }
}
.ce-textpic {
	img {
		width: 100%;
		height: auto;
	}

	&.ce-center {
		.ce-media {
			@include margin(null $gutter*-1);
		}
	}

	&.ce-intext {
		figure {
			@include material-shadow();
			display: table;
			margin: 0;
			background-color: $color-blue-lighter;
		}
		figcaption {
			font-size: 14px;
			padding: 10px 12px;
			max-width: 311px;
		}
	}

	&.ce-right {
		.ce-gallery {
			float: right;
			margin-right: $gutter*-1;
			margin-left: $gutter;
			margin-bottom: $gutter;
		}
	}

	@include media($screen-xs) {
		&.ce-right {
			.ce-gallery {
				float: none;
				margin-right: 0;
				margin-left: 0;
				margin-bottom: $gutter;
			}
		}
	}
}
#breadcrumb {
	@include outer-container();

	margin-top: 10px;
	margin-bottom: 20px;

	a {
		color: $color-text;
		font-size: 14px;
		font-weight: $weight-medium;
		@include transition(color 225ms easing());
		@include icon($fa-var-chevron-right, after) {
			font-size: 12px;
			margin: 0 10px;
			color: $color-blue;
		}
		&:hover {
			color: $color-blue;
			@include transition(color 225ms easing());
		}
		&:last-child {
			&:after {
				display: none
			}
		}
	}

	@include media($screen-lg) {
		padding: 0 30px;
	}

	@include media($screen-sm) {
		padding: 0 10px;
	}
}
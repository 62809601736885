@import "../../../Vendor/bourbon/app/assets/stylesheets/bourbon";
@import "../../../Vendor/neat/app/assets/stylesheets/neat-helpers";

@function column-width($span: $columns of $container-columns, $with-gutter: false) {
	$columns: nth($span, 1);
	$container-columns: container-span($span);
	@if $with-gutter == true {
		@return flex-grid($columns, $container-columns) + flex-gutter($container-columns);
	} @else {
		@return flex-grid($columns, $container-columns);
	}
}

@function easing($timing: swift) {
	@if $timing == swift {
		@return cubic-bezier(.4, 0, .2, 1);
	}
	@if $timing == swifter {
		@return cubic-bezier(.4, 0, 0, 1);
	}
	@if $timing == swift-in {
		@return cubic-bezier(.42, 0, 1, 1);
	}
	@if $timing == swift-out {
		@return cubic-bezier(0, 0, 0.58, 1);
	}
	@if $timing == heavy {
		@return cubic-bezier(.7, 0, .6, 1);
	}
}
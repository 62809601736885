.footer-social-media {

	ul {
		@include list-style-none;
		margin: 0 -2px;
	}

	li {
		display: inline-block;
	}

	a {
		display: block;
		position: relative;
		top: 0;
		margin: 0 2px;

		@include transition(all 125ms easing());

		span {
			@include visually-hidden;
		}

		&:hover {
			top: -2px;
			@include filter(drop-shadow(0 2px 2px rgba(0,0,0,0.25)));
		}

		&.facebook {
			@include retina-image('../Images/icon_facebook', 24px 24px);
			@include size(24px 24px);
		}

		&.twitter {
			@include retina-image('../Images/icon_twitter', 26px 20px);
			@include size(26px 20px);
		}

		&.linkedin {
			@include retina-image('../Images/icon_linkedin', 25px 24px);
			@include size(25px 24px);
		}
	}
}
.news {

	.date {
		font-size: 13px;
		margin-top: 20px;
		color: $color-text-grey;
	}

	.header {
		h1,
		h2 {
			font-size: 26px;
			line-height: 32px;
			font-weight: $weight-light;
			a {
				color: $color-blue-dark;
			}
		}
	}

	.more {
		display: block;
		margin-bottom: 20px;
	}

	.news-list-view {
		h2 {
			margin-top: 10px;
		}

		article {
			@include material-shadow();
			background-color: $color-white;
			padding: 0 30px;
			margin-bottom: 20px;
			overflow: auto;
		}

		.more-news {
			margin-top: -10px;
			text-align: right;
			a {
				color: $color-white;
			}
		}

		.article-header {
			margin-bottom: 20px;

			.date, h4 {
				font-size: 1em;
				color: $color-text;
			}

			h4 {
				margin-top: 0;
				margin-bottom: 0;
				font-weight: $weight-bold;
			}

		}

	}

	&.news-single {
		h1 {
			margin: 20px 0 10px;
		}
		.news-related {
			h2 {
				@extend h4;
				margin-bottom: 0;
			}
			ul {
				margin-top: 10px;
			}
			.news-related-files-size {
				display: block;
				font-size: 13px;
				color: $color-text-grey;
			}
			.links {
				span {
					display: block;
					font-size: 13px;
					color: $color-text-grey;
				}
			}
		}
		.news-backlink-wrap {
			margin-top: 20px;
			a {
				@extend .big-link;
				&:before {
					content: $fa-var-caret-left;
				}
			}
		}
	}
}

#sidebar {
	.news {

		p {
			margin: 10px 0;

			text-align: right;

			a {
				@extend .big-link;
			}
		}


		.news-list-view {

			.article {

				margin: 0 -25px;
				padding: 10px 25px 10px;


				&:nth-child(2n+1) {
					background: $color-row-odd;
				}

				.date, h4 {
					font-size: 14px;
					color: $color-text;
				}

				.date {
					margin-top: 0;
				}

				h4 {
					margin-top: 0;
					margin-bottom: 0;
					padding-right: 10px;
					font-weight: $weight-medium;
					line-height: 1.5em;
					@include hyphens();

					a {
						color: $color-text;
					}
				}


				$animation-duration: 225ms;
				@include material-shadow(0);
				@include transition(all $animation-duration easing());

				&:hover {
					background: $color-bg-grey-light;

					transform: scale(1.05);
					@include material-shadow(2);
					@include transition(all $animation-duration easing());

					.fa {
						color: $color-text;
					}
				}
			}
		}
	}
}

.news-search-form {
	@include material-shadow();
	background-color: $color-white;
	margin-bottom: 20px;
	padding: 0 30px;

	fieldset {
		padding-top: 30px;
	}

	input {
		background-color: $color-white;
		@include material-shadow();
	}

	.form-group {
		margin-bottom: 20px;
	}
}
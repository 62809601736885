.easc-tile {
    @include align-items(stretch);
    @include display(flex);
    @include flex-direction(row);
    @include justify-content(flex-start);

    position: relative;
    z-index: 1;
    align-items: center;

    float: left;
    width: 47%;
    height: 80px;
    padding: 20px 20px;

    background-color: $color-blue;
    @include background($gradient-button-light);

    &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
        @include transition(all 225ms easing());

        background-color: lighten($color-blue, 10%);
        @include background($gradient-button-light-hover);
    }

    &:hover {
        &:before {
            opacity: 1;
        }
    }

    margin: 20px 1.5% 0;

    @include material-shadow();

    &.halfsize {
        @include media($screen-md) {
            width: 97%;
        }
        @include media($screen-sm) {
            width: 47%;
        }
        @include media($screen-xs) {
            width: 97%;
        }
    }

    &.fullsize {
        width: 97%;
        margin-right: 0;
    }

    i {
        position: relative;
        top: 2px;
        @include flex(1);
        font-size: 50px;
        float: left;
        color: $color-white;
        margin-top: -5px;
    }

    h3 {
        @include flex(4);

        @include media($screen-md) {
            @include flex(7);
        }

        @include media($screen-sm) {
            @include flex(4);
        }

        @include media($screen-xs) {
            padding-left: 20px;
        }

        color: $color-white;
        margin: 0;
        font-size: 20px;
    }

    &.fullsize {
        h3 {
            @include flex(10);

            @include media($screen-md) {
                @include flex(7);
            }

            @include media($screen-sm) {
                @include flex(10);
            }

            @include media($screen-xs) {
                @include flex(4);
                padding-left: 20px;
            }

        }
    }
}
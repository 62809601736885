#sidebar {
    .tx-netl-newsletter-subscription {
        form {
            position: relative;
            background: $color-row-odd;
            display: block;
            margin: 0 -25px;
            padding: 10px;

            @include clearfix;

            input, button {
                display: block;
                height: 34px;
                margin: 0;
            }

            .text-field-wrapper {
                float: left;
                width: 100%;
                padding-right: 136px;
            }

            .captcha-field-wrapper {
                display: block;
                margin-top: 30px;
                width: 100%;
                position: relative;
                .frc-captcha {
                    width: 100% !important;
                }
            }

            button {
                position: absolute;
                right: 10px;
            }

            .frc-text {
                font-family: "ingra", sans-serif;
            }

            .frc-content button {
                position: relative;
                font-family: "ingra", sans-serif;
                font-weight: normal;
                color: $color-white;
                margin-bottom: 10px;
            }

            input {
                width: 100%;
                @include material-shadow();
                background-color: $color-white;
            }
        }

    }
}
.tx-netl-newsletter-subscription {

    label {
        display: inline;
    }

    .error {
        display: block;
        width: 60%;
        padding: 5px 10px;
        margin-top: 0;
        margin-bottom: 20px;
        z-index: 1;
        position: relative;
        font-size: 12px;
        background-color: $color-error;
        @include material-shadow();

        color: $color-white;
        font-weight: $weight-normal;

    }
}
fieldset {
	margin: 0;
	padding: 0;
	border: 0;
}

form {
	margin: 0;
	padding: 0;
	border: 0;
}

label {
    display: block;
	color: $color-text;
    font-weight: $weight-normal;
    margin-bottom: 4px;
}

select,
textarea,
input {
	width: 60%;

    margin: 0;
    padding: 0 10px;

    font-size: $base-font-size - 2px;
    color: $color-text;

    background: $color-white;

    border: none;

	cursor: pointer;

	&:focus {
		outline: none;
	}

    border-radius: 0;

    @include placeholder {
        color: $color-text-grey;
    }
}

textarea {
    height: 200px;
}

select,
input[type="text"],
input[type="date"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea {
    line-height: 34px;
    min-height: 34px;

    box-shadow: inset 0 1px 2px rgba(0,0,0,0.25);
    background-color: $color-blue-lighter;

    -webkit-appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
	float: left;
	line-height: 1em;
	width: auto;
	height: auto;
	margin: 2px 8px 0 0;

	& + label {
		display: block;
		margin-left: 20px;
		color: $color-text;
    }
}

input[type="checkbox"] {
    margin-bottom: 30px;
}

label.disabled {
    color: #aaa;
}


.radio-wrapper {
    float: left;
    margin-top: 8px;
    margin-right: 20px;
}

.select-wrapper {

    width: 60%;

    .select {

        position: relative;
        height: 32px;

        select {
            margin: 0;
            padding: 0 0 0 10px;
            height: 34px;
            position: relative;
            width: 100%;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        }

        @include icon($fa-var-angle-down, 'after') {
            position: absolute;
            width: 32px;
            height: 34px;
            font-size: 1.5em;
            line-height: 0.8;
            top: 0;
            bottom: 0;
            right: 0;
            color: $color-white;
            @include background($gradient-button-dark);
            text-align: center;
            padding-top: 8px;
            pointer-events: none;
            cursor: pointer;
        }

        &:hover {
            &:after {
                @include background($gradient-button-dark-hover);
            }
        }
    }
}

form button, .button {

    position: relative;
    z-index: 100;

    display: inline-block;

    width: auto;

    border: none;

    margin: 6px 10px 20px 0;
    padding: 0 11px;
    height: 34px;

    background-color: $color-blue-dark;

    @include background($gradient-button-dark);
    @include material-shadow(1);

    font-size: $base-font-size;
    font-weight: $weight-normal;
    line-height: 32px;
    color: $color-white;
    text-align: left;

    .fa {
        font-size: 19px;
        margin-right: 5px;
    }

    &:before {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0; left: 0;
        opacity: 0;
        width: 100%;
        z-index: -100;
        @include transition(all 225ms easing());
        background-color: mix($color-white, $color-blue-dark, 15%);
        @include background($gradient-button-dark-hover);
    }


    &.light {
        background-color: $color-blue;
        @include background($gradient-button-light);

        &:before {
            background-color: mix($color-white, $color-blue, 15%);
            @include background($gradient-button-light-hover);
        }
    }

    &:hover {
        cursor: pointer;
        &:before {
            opacity: 1;
        }
    }

    &.small {
        padding: 0 10px;
        height: 24px;
        font-size: 12px;
        line-height: 24px;

        .fa {
            margin: 0 6px 0 -2px;
            font-size: 12px;
        }

    }

}

.field-wrapper {
    .info-box {
        float: right;
        width: 40%;
    }
}
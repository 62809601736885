@import "../../../../Vendor/bourbon/app/assets/stylesheets/bourbon";
@import "../../variables/colors";
@import "../../utilities/mixins";

$animation-duration: 225ms;

#main-navigation {
	position: relative;
	z-index: 50;
	margin-top: 30px;

	ul {
		@include list-style-none;
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.level-1 {
		margin: 0 -10px;

		> li {

			position: relative;
			display: inline-block;
			margin-right: 15px;
			margin-bottom: 0;

			> a {
				display: block;
				position: relative;
				z-index: 1;
				font-size: 22px;
				color: $color-blue-dark;
				font-weight: 500;

				height: 60px;
				padding: 9px 10px;

				@include transition(color $animation-duration easing());
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				height: 0px;
				z-index: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background: $color-blue-darker;
				background: $gradient-button-dark;
				@include transition(height $animation-duration easing(), bottom $animation-duration easing())
			}

			&.active {
				&:after {
					height: 3px;
					bottom: 22px;
					margin:0 11px;
				}
			}

			&.hover,
			&:hover {

				&:after {
					height: 60px;
					bottom: 0;
					margin: 0;
					@include transition(margin $animation-duration easing(), height $animation-duration easing(), bottom $animation-duration easing())
				}

				> a {
					color: $color-white;
					@include transition(color $animation-duration easing());
				}
			}

			&.has-submenu {
				> a {
					height: 60px;
				}
			}
		}
	}

	.level-2 {
		position: absolute;

		background-color: $color-blue-darker;

		overflow: hidden;
		max-height: 0;
		padding: 0;

		@include transition(max-height $animation-duration easing());
		@include material-shadow(2);

		ul {
			margin: 5px 0;
		}

		li {
			position: relative;
			margin: 4px 0;
			/*
			&:after {
				position: absolute;
				z-index: 1;
				display: block;
				content: ' ';
				height: 28px;
				top: 0;
				left: 0;
				right: 100%;
				background-color: rgba($color-lilac,1);
				@include transition(right $animation-duration-200 easing());
			}
			&:hover {
				&:after {
					right: 0%;
					@include transition(right $animation-duration-200 easing());
				}
			}
			*/
		}

		a {
			position: relative;
			z-index: 2;
			display: block;
			color: $color-white;
			background-color: rgba($color-lilac,0);
			@include transition(background-color $animation-duration easing());
			font-size: 18px;
			font-weight: 500;
			padding: 4px 10px;
			white-space: nowrap;
			&:hover {
				background-color: rgba($color-lilac,1);
				@include transition(background-color $animation-duration easing());
			}
		}
	}

	.level-1 > {
		li {
			.hover,
			&:hover {
				$animation-add: 100ms;
				.level-2 {
					max-height: 200px;
					@include transition(max-height ($animation-duration + $animation-add) easing());
				}
				&.active {
					.level-2 {
						@include transition(max-height ($animation-duration + $animation-add) easing() ($animation-duration - 60ms));
					}
				}
			}
		}
	}

	@include media(max-width 1070px) {
		.level-1 {
			> li {
				body.lang-es & {
					margin-right: 0;

				}
			}
		}
	}
}


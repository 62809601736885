#meta-navigation {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 60;
	font-size: 14px;

	ul {
		@include list-style-none;
		display: inline-block;
		margin-right: 11px;
		li {
			display: inline-block;
			margin: 0 8px;
			a {
				color: $color-text;
				&.active {
					font-weight: $weight-bold;
				}
			}
		}
	}

	@include media($screen-lg) {
		right: 30px;
	}

	@include media($screen-md) {
		right: 20px;
	}

	@include media($screen-xs) {
		top: -50px;
		text-align: right;
		right: 15px;

		ul {
			margin: 0 0 15px 0;

			li {
				margin: 0 0 0 7px;
			}
		}

		body.lang-es & {
			font-size: 11px;

			li {
				margin: 0 0 0 4px;
				letter-spacing: -0.05em;
			}
		}
	}

	@include media(max-width 320px) {
		font-size: 12px;
	}
}
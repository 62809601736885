@import "../../../Vendor/bourbon/app/assets/stylesheets/bourbon";
@import "../utilities/utilities";

@import "typography";
@import "columns";

@import "pages/pages";

body {

}

#decoriation-europe {
	z-index: -1;
	position: absolute;
	top: 0;
	right: 0;
	background-repeat: no-repeat;
	background-position: 0px -10px;
	@include retina-image("../Images/europe", 516px 340px);
	background-size: 516px 340px;
	@include size(516px 340px);
	mix-blend-mode: multiply;
	opacity: 0.3;


	@include media($screen-sm) {
		background-position: 80px -10px;
	}

	@include media($screen-xs) {
		background-position: 80px -5px;
		$factor: 1.7;
		@include retina-image("../Images/europe", 516px/$factor 340px/$factor);
		@include size(516px/$factor 340px/$factor);
	}


	.lt-ie9 &, .ie9 & {
		//mix-blend-mode: normal;
	}
}

#logo {
	@include retina-image("../Images/logo", 260px 146px);
	@include size(260px 146px);

	display: block;
	margin-top: 5px;

	span {
		@include visually-hidden;
	}

	@include media($screen-sm) {
		margin-bottom: 20px;
	}

	@include media($screen-xs) {
		@include retina-image("../Images/logo_small", 182px 48px);
		@include size(182px 48px);
		margin-top: 40px;
		margin-bottom: 25px;
		margin-left: 15px;
	}


	@include media(max-width 320px) {
		@include retina-image("../Images/logo_very_small", 110px 30px);
		@include size(110px 30px);
		margin-bottom: 40px;
		position: relative;
		top: 27px;
	}
}

@include media($screen-sm) {
	#decoriation-europe,
	#mobile-navigation,
	#meta-navigation,
	#wrapper,
	#footer,
	#logo {
		@include transition(-webkit-filter 475ms linear, filter 475ms linear);

		.lt-ie9 &, .ie9 & {
			@include filter(none);
		}
	}
}

body {
	background: $color-blue url("../Images/background.jpg") center top no-repeat;
	margin: 0;
	padding: 20px 0 0 0;
	&.blur {
		#decoriation-europe,
		#mobile-navigation,
		#meta-navigation,
		#wrapper,
		#footer,
		#logo {
			@include filter(blur(10px));
			@include transition(-webkit-filter 475ms linear, filter 475ms linear);
		}
	}
}


#header,
#footer {
	@include outer-container();
}

.content-wrapper {
	@include media($screen-lg) {
		padding: 0 30px;
	}

	@include media($screen-sm) {
		padding: 0 10px;
	}
}

#header {
	position: relative;

	@include media($screen-lg) {
		margin: 0;
		@include padding(null 30px);
	}

	@include media($screen-xs) {
		margin: 0;
		@include padding(null 10px);
	}

	a.button {
		margin: 0 !important;
		position: absolute;
		top: 0;
		right: 220px;

		@include media($screen-lg) {
			right: 250px;
		}
		@include media($screen-xs) {
			top: -50px;
			right: 190px;
		}
	}
}

#footer {
	@include material-shadow();
	background-color: $color-blue-light; // fills up the social-media columns with blue color
	color: $color-text;
	margin-top: 20px;

	.footer-content {
		@include span-columns(8);
		background-color: $color-white;
		padding: 0 30px;
		width: flex-grid(8, 12) + flex-gutter();
		position: relative;
		left: (column-width(4, true) - flex-gutter()) * -1;
		font-size: 14px;
	}

	.footer-social-media {
		@include span-columns(4);
		width: flex-grid(4, 12);
		position: relative;
		margin-right: 0;
		left: column-width(8, true);
		padding: 10px flex-gutter();
		text-align: right;
		font-size: 12px;

		.meta-navigation {
			li a {
				font-size: 16px;
				color: $color-black;
			}
		}
	}

	@include media($screen-xs) {
		background-color: transparent;
		box-shadow: none;

		.footer-content {
			@include material-shadow();
			@include span-columns(4);
			background-color: $color-white;
			position: static;
		}
		.footer-social-media {
			@include material-shadow();
			background-color: $color-white;
			width: auto;
			position: static;
			float: right;
			margin-bottom: 10px;
			@include padding(null 15px);
		}
	}
}
#content {
    .tx-netlevents-pi1 {
        .event-list {
            .event-teaser {

                @include material-shadow();
                background-color: $color-white;
                padding: 0 column-width(1 of 8);
                margin-bottom: 20px;
                overflow: auto;

                .event-date {
                    margin-top: 20px;
                }

                h2 {
                    margin-top: 0;
                }

                .more {
                    display: inline-block;
                    @extend .big-link;
                    margin-bottom: 20px;
                }

            }

            .event-list-item {
                margin-bottom: 20px;

                .fa {
                    display: block;
                    float: left;
                    margin-top: 10px;
                    color: $color-text-grey;
                    font-size: 22px;
                }

                .fa-caret-right {
                    display: none;
                }

                .event-date, h4 {
                    margin-left: 40px;
                }

                .event-date {
                    color: $color-text;
                }

                h4 {
                    margin-top: 0;
                    margin-bottom: 0;
                    font-weight: $weight-bold;
                }
            }
        }


        .event-single-view {

            .image-wrap {
                padding-top: 30px;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            .event-date {
                margin-top: 20px;
            }
            h1 {
                margin-top: 0;
            }
        }
    }

    .event-search-form {
        @include material-shadow();
        background-color: $color-white;
        margin-bottom: 20px;
        padding: 30px;

        .select-filter {
            display: flex;
            margin-bottom: 20px;
            @include media($screen-xs) {
                display: block;
            }
            .select-wrapper {
                width: calc(33% - 20px);
                margin-right: 20px;
                @include media($screen-xs) {
                    width: calc(100%);
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }
        .date-input {
            display: flex;
            input {
                width: calc(33% - 20px);
                margin-right: 20px;
                @include media($screen-xs) {
                    width: calc(50% - 20px);
                }
            }
        }
    }
}

#sidebar {
    .tx-netlevents-pi1 {

        p {
            margin: 10px 0;

            text-align: right;

            a {
                @extend .big-link;
            }
        }


        .event-list {

            .event-list-item {

                margin: 0 -25px;
                padding: 10px 25px 10px;


                &:nth-child(2n+1) {
                  background: $color-row-odd;
                }

                .fa {
                    display: block;
                    float: left;
                    margin-top: 9px;
                    color: $color-white;
                    font-size: 22px;
                }

                .fa-caret-right {
                    float: right;
                    margin-top: 11px;
                }

                .event-date, h4 {
                    margin-left: 40px;
                    font-size: 14px;
                    color: $color-text;
                }

                h4 {
                    margin-top: 0;
                    margin-bottom: 0;
                    padding-right: 10px;
                    font-weight: $weight-medium;
                    line-height: 1.5em;
                    @include hyphens();
                }

                $animation-duration: 225ms;
                @include material-shadow(0);
                @include transition(all $animation-duration easing());

                &:hover {
                    background: $color-bg-grey-light;

                    transform: scale(1.05);
                    @include material-shadow(2);
                    @include transition(all $animation-duration easing());

                    .fa {
                        color: $color-text;
                    }
                }
            }
        }
    }
}



#mobile-navigation {
	display: none;
}

#main-navigation {
	.close-navigation {
		display: none;
	}
}

@include media(max-width 1009px) {

	#main-navigation,
	#mobile-navigation {
		button {
			@include transition(all 175ms easing());
			background-color: transparent;
			border: 0;
			margin: 0 0 0 20px;
			padding: 0;
			cursor: pointer;
			color: $color-text;

			&:hover {
				@include transform(scale(1.1));
				color: $color-blue;
			}

			&:before {
				position: relative;
			}

			span {
				@include visually-hidden;
			}
		}
	}

	#mobile-navigation {
		display: block;
		position: absolute;
		z-index: 60;
		right: 30px;
		top: 180px;
		button {
			display: none;
			&.open-search {
				display: inline-block;
				@include icon($fa-var-search) {
					top: -3px;
				}
				font-size: 25px;
			}

		}
	}
}

@include media(max-width 840px) {

	#mobile-navigation {
		display: block;
		position: absolute;
		right: 20px;
		top: 115px;

		button {
			display: inline-block;
			&.open-search {
				@include icon($fa-var-search) {
					top: -3px;
				}
				font-size: 25px;
			}

			&.call {
				@include icon($fa-var-phone);
				font-size: 30px;
			}

			&.open-navigation {
				@include icon($fa-var-bars);
				font-size: 30px;
			}
		}
	}
	#main-navigation,
	#mobile-navigation {
		button {
			&.close-navigation {
				@include icon($fa-var-close);
				display: block;
				font-size: 40px;
				position: absolute;
				z-index: 10;
				top: 20px;
				right: 30px;
				color: $color-white;
			}
		}
	}

	#main-navigation {
		@include material-shadow(3);
		@include transition(
			left 475ms easing(swift-out),
			right 475ms easing(swift-out),
			opacity 475ms easing(),
			visibility 0ms linear 475ms
		);
		visibility: hidden;
		//opacity: 0;
		overflow-x: scroll;
		position: fixed;
		top: -30px;
		left: -100%;
		bottom: 0;
		right: 100%;

		@include background(linear-gradient(rgba($color-blue, 0.8), rgba($color-blue-dark, 0.80)));

		padding: 20px 40px 20px 40px;

		&.show {
			@include transition(
				left 475ms easing(swift-out),
				right 475ms easing(swift-out),
				opacity 475ms easing(),
				visibility 0ms linear
			);
			visibility: visible;
			//opacity: 1;
			left: 0;
			right: 10%;
		}

		.level-1 {
			li {
				display: block;
				height: auto;
				&:after {
					display: none;
				}
				a {
					height: auto;
					color: $color-white;
					font-size: 30px;
					line-height: 1.5em;
				}
				&:hover {
					.level-2 {
						display: none;
						max-height: none;
					}
				}
			}
		}
		.level-2 {
			display: none;
			position: static;
			background: none;
			box-shadow: none;
			max-height: none;
			border-top: 1px solid $color-white;
			border-bottom: 1px solid $color-white;
			&.open {
				display: block;
			}
			li {
				a {
					font-size: 24px;
					line-height: 1.2em;
					background: none;
					white-space: normal;
					@include hyphens(auto);

					padding: 10px;
				}
			}
		}
	}
}

@include media($screen-xs) {
	#mobile-navigation {
		top: 25px;
		right: 15px;
	}

	#main-navigation {
	}
}
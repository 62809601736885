.tx-netl-social-media {
    &.inactive {
        .netl-social-media-inner-wrap {
            border: 1px solid $color-blue;
            background-color: $color-blue-lighter;
            padding: 20px;
        }
    }

    .netl-social-media-inner-wrap {
        margin-top: 10px;
        margin-bottom: 20px;
    }

    h2 {
        margin-top: 0;
    }

    button {
        border: 0;

        background-color: $color-blue-dark;

        @include background($gradient-button-dark);
        @include material-shadow(1);

        color: $color-white;

        display: inline-block;

        padding: 6px 16px 9px;

        font-size: 1em;

        &:hover {
            background-color: $color-blue-light;
        }
    }
}

.youtube-wrap {
    position: relative;
    padding-bottom: 55%;
    padding-top: 15px;
    height: 0;
    overflow: hidden;
    margin-bottom: 10px;
}
.youtube-wrap iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


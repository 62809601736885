@import "../basics/grid";
@import "../variables/colors";
@import "../variables/typography";
@import "../utilities/mixins";
@import "../../../Vendor/neat/app/assets/stylesheets/neat-helpers";
@import "../../../Vendor/neat/app/assets/stylesheets/grid/media";

body {
	font-family: "ingra", sans-serif;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-weight: $weight-light;
	color: $color-text;
}

b,
strong {
	font-weight: $weight-medium;
}

a {
	text-decoration: none;
	color: $color-blue;

	p & {
		text-decoration: underline;
	}

	&.mail,
	&.download,
	&.big-link,
	&.external-link,
	&.external-link-new-window {
		&:before {
			margin: 0 6px;
			position: relative;
			top: 1px;
		}
	}

	&.big-link {
		@include transition(color 275ms easing());
		@include icon($fa-var-caret-right) {
			position: relative;
			top: 2px;
			left: 0;
			font-size: 1.4em;
			margin: 0 5px 0 0;
			@include transition(left 275ms easing());
		}
		color: $color-text;
		font-weight: $weight-bold;
		text-decoration: none;

		&:hover {
			color: mix($color-white, $color-text, 25%);
			&:before {
				left: 2px;
			}
		}
	}
	&.mail {
		@include icon($fa-var-envelope);
	}
	&.download {
		@include icon($fa-var-download);
	}
	&.external-link,
	&.external-link-new-window {
		@include icon($fa-var-external-link-square);
	}
}

p {
	margin: 20px 0;
}

h1,h2,h3,h4 {
	font-weight: $weight-medium;
	color: $color-blue-darker;
	margin: 20px 0;
}

h1 {
	font-size: 26px;
	line-height: 32px;
}

h2 {
	font-size: 22px;
	line-height: 28px;
}

h3 {
	font-size: 18px;
	line-height: 24px;
}

h4 {
	font-size: $base-font-size;
	line-height: $base-line-height;
}

ul {
	list-style: none;
	padding-left: 30px;
	li {
		position: relative;
		margin-bottom: 10px;
		
		&:before {
			content: '';
			position: absolute;
			top: 6px;
			left: -18px;
			display: block;
			@include size(6px 6px);
			background-color: $color-blue;
			border-radius: 3px;
		}
	}
	&.icons {
		@include list-style-none;
		padding-left: 30px;
		li {
			position: relative;
			//margin-left: -30px;
			&:before {
				position: absolute;
				top: 1px;
				left: -24px;

				color: $color-blue-dark;
				margin-right: 4px;

			}
		}
	}
	&.downloads {
		li {
			@include icon($fa-var-download) {
			}
		}
	}
	&.links {
		li {
			@include icon($fa-var-link) {
			}
		}
	}
}

dl {
	dt {
		float: left;
		line-height: 30px;
		font-weight: $weight-medium;
	}

	dd {
		margin-left: 220px;
		line-height: 30px;
	}
}

p {
	&.align-left,
	&.text-left {
		text-align: left;
	}
	&.align-center,
	&.text-center {
		text-align: center;
	}
	&.align-right,
	&.text-right {
		text-align: right;
	}
}

@include b('cookie-optin') {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    background-color: $color-blue-light;
    box-shadow: 0 0 5px rgba($color-black, 0.7);

    @include e('content') {
        padding: 0 50px 10px;
        position: relative;

        @media only screen and (max-width: 768px) {
            padding: 0 20px;
        }
        @media only screen and (max-width: 480px) {
            padding: 0 10px;
        }

        ul {
            @include clearfix;
            margin: 0;
            padding: 0;

            li {
                list-style: none outside none;
                width: 33.3333%;
                float: left;

                @media only screen and (max-width: 768px) {
                    width: 100%;
                    float: none;
                    margin-bottom: 10px;
                }

                &:before {
                    display: none;
                }

                label {
                    font-size: 16px;
                }

                p {
                    margin: 0 0 5px 20px;

                    @media only screen and (max-width: 480px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }
            }
        }

        @include e('buttons') {
            text-align: right;

            @include e('button') {
                display: inline-block;
                transition: all 225ms cubic-bezier(.4, 0, .2, 1);
                background-color: #353696;
                background: linear-gradient(#8889c2, #0c0e5e);
                color: $color-white;
                border: none;
                cursor: pointer;
                margin-bottom: 5px;

                @media only screen and (max-width: 768px) {
                    position: relative;
                    right: initial;
                    margin: 0 auto;
                }

                @include m('submit') {
                    padding: 2px 8px;
                    font-size: 10px;

                    @media only screen and (max-width: 768px) {
                        bottom: initial;
                        margin-bottom: 20px;
                    }
                }

                @include m('allow-all-cookies') {
                    padding: 8px;
                    font-size: 13px;

                    @media only screen and (max-width: 768px) {
                        bottom: initial;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
}

button.cookie-info__button {
    display: inline-block;
    transition: all 225ms cubic-bezier(.4, 0, .2, 1);
    background-color: #353696;
    background: linear-gradient(#8889c2, #0c0e5e);
    color: $color-white;
    border: none;
    cursor: pointer;

    padding: 10px 8px;
    font-size: 12px;
}

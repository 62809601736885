.ce-full-size-teaser {
	position: relative;
	background-position: center center;
	background-size: cover;
	@include material-shadow(1);

	.ce-container {
		@include outer-container();
		height: 490px;
	}

	.ce-bodytext {
		@include span-columns(6);
		@include material-shadow(2, yes);
		background-color: $color-white;
		margin-top: 12%;
		padding: 10px 30px 0px;

		font-size: 18px;
		line-height: 24px;

		h1,h2,h3,h4,h5 {
			font-size: 24px;
			margin: 15px 0 5px;
		}
	}

	@include media($screen-md) {
		.ce-bodytext {
			margin-left: column-width(1);
		}
	}

	@include media($screen-sm) {
		.ce-bodytext {
			@include shift(1);
		}
	}

	@include media($screen-xs) {
		display: none;
	}
}
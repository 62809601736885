.tx-easc-members {

    > section,
    > form > section,
    .easc-member-list-item,
    .easc-local-group-list-item,
    .easc-institute-list-item,
    .easc-member-profile > section {
        margin-bottom: 20px;
        padding: 20px 30px;

        overflow: hidden;

        background: $color-white;

        @include material-shadow(1);
        @include clearfix;
    }

    #easc-members-gmap {
        display: block;
        height: 300px;
    }

    .search-form {
        padding-top: 0;
        padding-bottom: 0;

        #easc-members-gmap {
            margin: -20px -30px 30px -30px;

            @include media($screen-sm) {
                margin: 0;
            }

            p {
                margin: 5px 0 0 0;
            }
        }
    }


    .closed, .do-not-display {
        display: none;
    }

    #progress-indicator {

        @include list-style-none;
        padding: 0;
        margin: -20px -30px 20px;

        @include align-items(stretch);
        @include display(flex);
        @include flex-direction(row);
        @include justify-content(flex-start);

        li {
            @include flex(1);

            padding: 10px 14px;

            font-size: 12px;
            color: $color-text;

            background: $color-blue-lighter;

            border-left: 1px solid $color-blue-light;

            &:first-child {
                border-left: none;
            }

            span {
                display: block;
                font-weight: $weight-bold;
            }

            &.active {
                background: $color-blue-light;
            }

            &:hover {
                cursor: pointer;
                @include material-shadow(1);
            }
        }
    }

    .preview-data {

        .image-preview {
            @include outer-container();
            > div {
                @include span-columns(4 of 8);
            }

            label {
                display: block;
                font-size: 12px;
                margin-bottom: 10px;
            }

        }

        p.info {
            font-size: 14px;
        }

        ul.portfolio {
            width: 100%;

            @include clearfix;
            li {
                float: left;
                width: 50%;
            }
            li:nth-child(even) {
                margin-right: 0;
            }

            @include media($screen-xs) {
                li {
                    float: none;
                    width: 100%;
                }

            }

        }
    }


    .error-header {
        display: block;
        @include material-shadow(1);
        background: $color-error;

        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 10px 10px;

        p {
            margin: 0;
            @include clearfix;

            span {
                display: block;
                color: $color-white;
            }

            .fa-exclamation-circle {
                float: left;
                font-size: 40px;
                margin-right: 10px;
                margin-top: -10px;

                color: $color-white;
            }
        }
    }

    .success-header {
        display: block;
        @include material-shadow(1);
        background: green;

        margin-top: 20px;
        margin-bottom: 20px;
        padding: 20px 10px 10px;

        p {
            margin: 0;
            @include clearfix;

            span {
                display: block;
                color: $color-white;
            }

            .fa-check-circle {
                float: left;
                font-size: 40px;
                margin-right: 10px;
                margin-top: -10px;

                color: $color-white;
            }
        }
    }

    .error {
        display: block;
        width: 60%;
        padding: 5px 10px;
        margin-bottom: 20px;
        z-index: 1;
        position: relative;
        font-size: 12px;
        background-color: $color-error;
        @include material-shadow();

        color: $color-white;
        font-weight: $weight-normal;
    }

    .select-wrapper .error {
        width: 100%;
        margin-top: 3px;
    }


    .field-wrapper {
        margin-bottom: 20px;

        @include clearfix;

        &.membership-conditions {
            margin-bottom: 0;
        }
    }

    .field-wrapper.address-privacy .radio-wrapper {
        float: none;
    }

    .field-wrapper.debit-info {
        padding-left: 20px;
    }

    .field-note {
        margin-top: 3px;
        margin-bottom: 10px;
        color: $color-text-dark;
        font-size: 0.8em;
    }

    .country-selector, .public-country-selector {

        padding: 10px 0 0 0 !important;

        @include list-style-none;

        li {
            display: block;
            float: left;
            margin-right: 10px;

            .flag-de, .flag-ch, .flag-at, .flag-es, .flag-en, .flag-cz, .flag-sk, .flag-hu {
                position: relative;
                top: 0;
                height: 20px;
                width: 30px;
                background: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-de.png') no-repeat;
                background-size: 100%;
                @include material-shadow();
                @include transition(all 275ms easing());

                &:hover {
                    cursor: pointer;
                    top: -2px;
                    @include transform(scale(1.1));
                    @include material-shadow(2);
                }
            }

            .flag-es {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-es.png');
            }

            .flag-ch {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-ch.png');
            }

            .flag-at {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-at.png');
            }

            .flag-en {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-en.png');
            }

            .flag-cz {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-cz.png');
            }

            .flag-sk {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-sk.png');
            }
            .flag-hu {
                background-image: url('/typo3conf/ext/easc_members/Resources/Public/Icons/flag-hu.png');
            }
        }
    }

    .language-select-wrapper {
        .select-wrapper {
            margin-bottom: 10px;
        }
    }

    .language-panel-wrapper {

        margin: 20px 0 0 0;

    }

    #easc-members-image-upload-button {
        display: none;
    }

    .centerPoint {
        position: absolute;
        height: 20px;
        width: 20px;
        border: 2px solid white;
        border-radius: 10px;
        background: red;
        display: none;
    }

    .portfolio-selector {

        width: 100%;

        @include list-style-none;

        li {
            float: left;
            width: 50%;
            margin-bottom: 10px;
        }
        li:nth-child(even) {
            margin-right: 0;
        }
    }

    .file-list {
        .fa-check {
            color: green;
            font-size: 20px;
        }
    }

    .easc-member-profile {

        > section {
            padding: 0;
        }

        > section.member-map {
            max-height: 0;
        }

        .member-data {
            position: relative;

            .image-wrap {
                @include span-columns(3 of 8);
                margin-right: 0;
                padding-right: 30px;

                img {
                    max-width: 100%;
                    height: auto;
                }

                @include media($screen-md) {
                    //padding: 30px;
                }

                @include media($screen-xs) {
                    display: none;
                }
            }

            .landscape-image-wrap {

                @include span-columns(8 of 8);
                @include omega();

                display: none;

                img {
                    max-width: 100%;
                    height: auto;
                }

                @include media($screen-xs) {
                    display: block;
                }

            }

            .logo-wrap {
                position: absolute;
                top: 20px;
                right: 20px;

                @include media($screen-md) {
                    display: none;
                }
                @include media($screen-sm) {
                    display: block;
                }
                @include media($screen-xs) {
                    display: none;
                }
            }

            .member-contact-data {
                @include span-columns(4 of 8);
                @include omega();

                &.no-image {
                    @include span-columns(8 of 8);
                    padding: 0 30px;
                }

                @include media($screen-xs) {
                    @include span-columns(8 of 8);
                    padding: 0 30px 30px;
                }

                h1 {
                    margin-bottom: 0;
                }

                h3 {
                    margin-top: 0;
                }

                .button-wrap {
                    margin-bottom: 5px;
                }

                .fa {
                    width: 18px;
                }

            }
        }

        .member-portfolio, .member-languages {
            padding: 10px 30px;

            @include portfolio-list();

        }

        .member-information {
            .accordion.initialized {
                margin: 0;
            }
        }
    }

    .easc-member-search-form, .easc-local-group-search-form , .easc-institute-search-form {

        .keyword-search,
        .extended-search,
        .country-search {
            margin-bottom: 20px;
        }

        .keyword-search {

            @include outer-container;

            .keyword-wrapper {
                @include span-columns(6 of 8);
                input {
                    width: 100%;
                }

                @include media($screen-xs) {
                    @include span-columns(8 of 8);
                }
            }

            .submit-wrapper {
                @include span-columns(2 of 8);
                @include omega();
                button {
                    margin: 0;
                    width: 100%;
                }

                @include media($screen-xs) {
                    @include span-columns(4 of 8);
                }
            }
        }

        .extended-search {
            overflow: hidden;
            max-height: 0;
            margin-bottom: 0;
            @include transition(max-height 0.5s, margin-bottom 0.5s);

            &.open {
                margin-bottom: 20px;
                max-height: 250px;
                @include transition(max-height 0.5s, margin-bottom 0.5s);
            }

            @include outer-container;

            .city-wrapper {
                @include span-columns(4 of 8);
                input {
                    width: 100%;
                }

                @include media($screen-xs) {
                    @include span-columns(8 of 8);
                }

            }

            .distance-wrapper {
                @include span-columns(2 of 8);
                @include omega();

                .select-wrapper {
                    width: 100%;
                }

                @include media($screen-xs) {
                    @include span-columns(4 of 8);
                }

            }

            ul.portfolio {
                @include list-style-none;
                width: 100%;
                li {
                    float: left;
                    width: 50%;
                }
                li:nth-child(even) {
                    margin-right: 0;
                }

                @include media($screen-xs) {
                    li {
                        float: none;
                        width: 100%;
                    }

                }

            }

        }

        .country-search {

            @include outer-container;
            @include clearfix;

            .country-wrapper {
                @include span-columns(6 of 8);
                .select-wrapper {
                    width: 100%;
                }
            }

            .submit-wrapper {
                @include span-columns(2 of 8);
                @include omega();
                button {
                    margin: 0;
                    width: 100%;
                }

                @include media($screen-xs) {
                    @include span-columns(4 of 8);
                }
            }


            .extended-search-trigger {
                @include span-columns(2 of 8);
                @include omega();

                button {
                    //margin: 0;
                    width: 100%;
                }
            }
        }
    }

    .easc-member-list-item {
        @include outer-container;
        padding: 0;
        margin-bottom: 20px;

        .image-wrap {
            @include span-columns(3 of 8);

            img {
                max-width: 100%;
                height: auto;
            }

            @include media($screen-md) {
                @include span-columns(4 of 8);
            }

            @include media($screen-xs) {
                display: none;
            }

        }

        .landscape-image-wrap {

            margin-bottom: 20px;

            @include span-columns(8 of 8);
            @include omega();

            display: none;

            img {
                max-width: 100%;
                height: auto;
            }

            @include media($screen-xs) {
                display: block;
            }
        }

        .info-wrap {
            @include span-columns(5 of 8);
            @include omega();

            padding: 20px 30px 0 0;

            &.no-image {
                @include span-columns(8 of 8);
                @include omega();
                padding: 20px 30px 0;
            }

            @include media($screen-md) {
                @include span-columns(4 of 8);
            }

            @include media($screen-xs) {
                @include span-columns(8 of 8);
                padding: 20px 30px 0;
            }

            h3 {
                margin-top: 0;
            }

            @include portfolio-list();

        }
    }

    .easc-institute-list {

        .easc-institute-list-item {

            @include outer-container;

            padding-bottom: 0;
            margin-bottom: 20px;

            .logo-wrap {
                @include span-columns(2 of 8);
                margin-bottom: 20px;
                img {
                    max-width: 100%;
                    height: auto;
                }

                @include media($screen-xs) {
                    display: none;
                }

            }

            .landscape-logo-wrap {

                margin-bottom: 20px;

                @include span-columns(8 of 8);
                @include omega();

                display: none;

                img {
                    max-width: 100%;
                    height: auto;
                }

                @include media($screen-xs) {
                    display: block;
                }
            }

            .info-wrap {
                @include span-columns(6 of 8);
                @include omega();

                @include media($screen-xs) {
                    @include span-columns(8 of 8);
                }

                h3 {
                    margin-top: 0;
                }

            }
        }
    }

    .easc-local-group-list-item {
        padding-bottom: 0;
        .info-wrap {
            h3 {
                margin-top: 0;
            }
        }
    }


    .easc-institute-profile {

        > section {
            margin-bottom: 20px;
            padding: 30px;
            overflow: hidden;

            background: $color-white;

            @include material-shadow(1);

            @include clearfix;
        }

        .institute-data {

            .logo-wrap {
                @include span-columns(3 of 8);

                img {
                    max-width: 100%;
                    height: auto;
                }

                @include media($screen-xs) {
                    display: none;
                }
            }

            .landscape-logo-wrap {
                display: none;

                img {
                    max-width: 100%;
                    height: auto;
                }

                @include media($screen-xs) {
                    display: block;
                    margin: -30px -30px 30px -30px;
                }
            }

            .institute-contact-data {

                @include span-columns(5 of 8);

                @include media($screen-xs) {
                    @include span-columns(8 of 8);
                }

                .fa {
                    width: 18px;
                }
            }
        }

    }

    .easc-local-group-profile {

        > section {
            margin-bottom: 20px;
            padding: 30px;
            overflow: hidden;

            background: $color-white;

            @include material-shadow(1);

            @include clearfix;

            .ce-textpic.ce-center.ce-above {
                margin-top: -30px;
            }

            .local-group-contact-person {
                @include span-columns(4 of 8);
                @include media($screen-md) {
                    @include span-columns(8 of 8);
                }
            }
        }
    }

    #member-search {
        display: block;
        background: $color-row-odd;
        margin: 0 -30px;
        padding: 20px 20px 10px 20px;
    }


    .direct-links {
        @include list-style-none;
        padding: 0;
        margin: 0 -30px -20px;

        @include align-items(stretch);
        @include display(flex);
        @include flex-direction(row);
        @include justify-content(flex-start);

        li {
            @include flex(1);
            padding: 10px 14px;

            background: $color-blue-lighter;

            margin-bottom: 0;

            a {
                color: $color-text;

                @extend .big-link;
            }


            border-left: 1px solid $color-blue-light;

            &:first-child {
                border-left: none;
            }

            &.active {
                background: $color-blue-light;
            }

            &:hover {
                cursor: pointer;
                @include material-shadow(1);
            }
        }
    }

}



.cookie-disclaimer {

    z-index: 99;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    padding-top: 18px;
    box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.11), 0 -1px 0 rgba(0, 0, 0, 0.24);

    background-color: $color-blue-light;

    a {
        color: #fff;
        text-decoration: underline;
    }

    &__content {
        margin: 0 auto;
        width: 100%;
        max-width: 1200px;
        padding: 0 20px;
    }

    &__message {
        margin-top: 5px;
        float: left;
        color: $color-text;
        text-align: center;
        a {
            color: $color-text;
        }
    }

    &__dismiss {
        position: relative;
        top: -5px;

        z-index: 100;
        width: auto;
        border: none;
        padding: 0 11px;
        height: 34px;
        background-color: #111384;
        background: -webkit-linear-gradient(#111384, #0c0e5e);
        background: linear-gradient(#111384, #0c0e5e);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11), 0 1px 1px rgba(0, 0, 0, 0.24);
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        color: #fff;
        text-align: left;

        float: none;
        display: block;
        margin: 0 auto;
    }
}

.sk-fading-circle {
    margin: 50px auto;
    width: 40px;
    height: 40px;
    position: relative;
}

.sk-fading-circle .sk-circle {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-fading-circle .sk-circle:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
    animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.sk-fading-circle .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.sk-fading-circle .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}
.sk-fading-circle .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}
.sk-fading-circle .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}
.sk-fading-circle .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
.sk-fading-circle .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}
.sk-fading-circle .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}
.sk-fading-circle .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}
.sk-fading-circle .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
    0%, 39%, 100% { opacity: 0; }
    40% { opacity: 1; }
}

#content {
    .two-column-container {
        @include outer-container();
        .left, .right {
            @include span-columns(4 of 8);
        }
        .right {
            @include omega();
        }

        @include media($screen-md) {
            .left, .right {
                @include span-columns(8 of 8);
                @include omega();
            }

        }
    }
}
.tx-powermail {

    legend {
        display: none;
    }

    .powermail_field {
        width: 60%;
        position: relative;
        display: inline-block;
        @include clearfix;

        input, select, textarea {
            width: 100%;
        }

        .select {
            &:after {
                display: inline-block;
                font: normal normal normal 14px/1 FontAwesome;
                font-size: inherit;
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: "";
                position: absolute;
                width: 32px;
                height: 34px;
                font-size: 1.5em;
                line-height: 0.8;
                top: 0;
                bottom: 0;
                right: 0;
                color: #fff;
                background: -webkit-linear-gradient(#111384, #0c0e5e);
                background: linear-gradient(#111384, #0c0e5e);
                text-align: center;
                padding-top: 8px;
                pointer-events: none;
                cursor: pointer;
            }
        }


    }



    div.powermail_fieldwrap_land {
        width: 100%;
    }

    .powermail_submit {
        background-color: $color-blue-dark;
        color: $color-white;
    }


    .powermail_fieldwrap {
        margin-bottom: 20px;

        .parsley-errors-list {
            margin: 0;
            padding: 0;

            li {
                display: block;
                width: 100%;
                padding: 5px 10px;
                margin-bottom: 20px;
                z-index: 1;
                position: relative;
                font-size: 12px;
                background-color: #d88080;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11), 0 1px 1px rgba(0, 0, 0, 0.24);
                color: #fff;
                font-weight: 400;

                &:before {
                    display: none;
                }
            }
        }

        &.powermail_fieldwrap_type_check {

            .powermail_label {
                display: none;
            }

            .powermail_field {
                height: auto;
            }

            .checkbox {
                @include clearfix();

                .powermail_checkbox {
                    float: left;
                    width: 20px;
                    height: 20px;
                    vertical-align: top;
                    margin: 2px 10px 10px 0;
                }

                label {
                    font-weight: 600;

                    span {
                        float: left;
                        width: calc(100% - 30px);
                        margin: 0 0 5px;
                    }
                }
            }
        }

        &.powermail_fieldwrap_type_content {
            width: 100%;

            .powermail_field {
                height: auto;

                b {
                    font-weight: 600;
                }
            }
        }
    }

}


.form-back,
.form-proceed {

    input.btn {
        position: relative;
        z-index: 100;
        display: inline-block;
        width: auto;
        border: none;
        margin: 6px 10px 20px 0;
        padding: 0 11px;
        height: 34px;
        background-color: #111384;
        background: -webkit-linear-gradient(#111384, #0c0e5e);
        background: linear-gradient(#111384, #0c0e5e);
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.11), 0 1px 1px rgba(0, 0, 0, 0.24);
        font-size: 16px;
        font-weight: 400;
        line-height: 32px;
        color: #fff;
        text-align: left;
    }

}

.form-back {
    float: left;
}

.form-proceed {
    float: right;
}